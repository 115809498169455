import router from "./router";
import store from "./store";
import global from "./common/config.js/global";

router.beforeEach(async (to, from, next) => {
  const whiteList = ["/login", "/register"];

  document.title = "Grand Lisboa";

  const hasToken = localStorage.getItem(global.tokenName);
  if (hasToken) {
    if (to.path == "/login") {
      next({ path: "/" });
    } else {
      const hasUserInfo = localStorage.getItem("userInfo");
      if (hasUserInfo) {
        next();
      } else {
        try {
          await store.dispatch("getInfo");
          await store.dispatch("getConfig");
          next();
        } catch (err) {
          await store.dispatch("logout");
          next({ path: "/login" });
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next({ path: "/login" });
      // NProgress.done()
    }
  }
});
