// axios.js

import axios from "axios";
import global from "./config.js/global";
import { Toast } from "vant";

// 创建一个 Axios 实例
const request = axios.create({
  // baseURL: 'http://localhost:9327',
  baseURL: "/app",
  timeout: 50000, // 设置超时时间
});

// 添加请求拦截器
request.interceptors.request.use(
  (config) => {
    // 在发送请求之前做一些处理，比如添加loading效果
    config.headers[global.tokenName] = localStorage.getItem(global.tokenName);
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  (response) => {
    // 对响应数据做一些处理，比如解析响应结果
    let res = response.data;
    if (res.code == 200 || res.code == 20010 || res.code == 20011) {
      return response.data;
    } else if (res.code == 401) {
      // console.log("123")
      if (!localStorage.getItem(global.tokenName)) {
        Toast.fail("account or password error");
      }
      location.reload();
    }
    return Promise.reject(new Error(""));
  },
  (error) => {
    // 对响应错误做些什么
    return Promise.reject(error);
  }
);

export default request;
