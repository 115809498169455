export default {
  nav: {
    home: '首页',
    sign: '登陆',
    language: '切换语言',
    notice: '公告列表',
    noticeDetail: '公告详情',
    wallet: '钱包',
    rule: '游戏规则',
    withdraw: '提现',
    fundsflow: '账户流水记录'
  },
  tabbar: {
    home: '首页',
    recharge: '充值',
    contact: '客服',
    account: '我的'
  },
  language: {
    cn: '中文简体',
    tcn: '中文繁体',
    en: '英语',
    jp: '日语',
    ko: '韩语',
    da: '印地语'
  },
  button: {
    language: '确定',
    recharge: '提交充值',
    betting: '投注',
    betting1: '返回',
    withdraw: '提现',
    not1: '底注',
    not2: '准入',
    account: '提交',
    login: '登陆',
    register: '注册'
  },
  account: {
    profit: '今日盈亏',
    win: "今日中奖",
    balance: '余额',
    recharge: '充值',
    withdraw: '提现',
    wallet: '钱包',
    notice: '公告',
    funds: '交易记录',
    pass: '修改登陆密码',
    password: '修改资金密码',
    exit: '安全退出',
    arecord: '帐变记录',
    rrecord: '充值记录',
    wrecord: '提现记录',
  },
  home: {
    trade: '交易大厅',
    play: '游戏玩法'
  },
  rule: {
    title: '游戏玩法',
    p1: '0-13为小,14-27为大',
    p2: '单双:',
    p3: '开出的号码为双数叫双,例:0,2,4,6,8,10',
    p4: '开出的号码为单数叫单,例:1,3,5,7,9,11',
    p5: '大单大双:',
    p6: '出的号码≥14为大且为双数,例;14(大双)15(大单)',
    p7: '小单小双:',
    p8: '开出的号码≤13为小且为单数,例:13(小单)12(小双)',
    p9: '小单小双:',
    p10: '小单小双:',
    p11: '游戏规则:',
    p12: '1.上分即本金的一倍流水,方可提现。',
    p13: '2.禁止同号或以其他方式进行大小单双对刷行为。',
    p14: '3.严禁恶意套取彩金。',
    p15: '4.晋级会员需完成相对应流水方可提现。',
    p16: '【注:最终解释权归本平台所有~】',
  },
  indoor: {
    size: '大小',
    number: '数字',
    special: '特殊',
    until: '截止',
    period: '期',
    user: '用户',
    number: '轮数',
    categroy: '交易品类',
    transaction: '单轮交易',
    operation: '操作',
    balance: '余额',
    periods: '期数',
    total: '总和',
    result: '结果',
    text: '选择玩法，点击金额投注'
  },
  withdraw: {
    cash: '提现金额',
    all: '全部提现'
  },
  register: {
    title: 'Fill in',
    username: 'Please enter 6-20 letters, numbers or combinations',
    step: 'Next step',
    register: 'Member registration'
  }
}