export default {
  nav: {
    home: "मुखपृष्ठ",
    sign: "लॉग इन करें",
    language: "भाषा बदलें",
    notice: "घोषणा सूची",
    noticeDetail: "घोषणा विवरण",
    wallet: "बटुआ",
    rule: "खेल के नियम",
    withdraw: 'निकालना',
    fundsflow: 'लेखांकन परिवर्तन रिकॉर्ड'
  },
  tabbar: {
    home: "मुखपृष्ठ",
    recharge: "फिर से दाम लगाना",
    contact: "संपर्क",
    account: "खाता",
  },
  language: {
    cn: "सरलीकृत चीनी",
    tcn: "परंपरागत चीनी",
    en: "अंग्रेजी भाषा",
    jp: "जापानी",
    ko: "कोरियाई",
    da: "हिन्दी"
  },
  button: {
    language: "ठीक है",
    recharge: "रिचार्ज सबमिट करें",
    betting: 'शर्त',
    betting1: 'वापस करना',
    withdraw: 'निकालना',
    not1: 'बटन नोट',
    not2: 'पहुँच',
    account: 'जमा करना',
    login: 'लॉग इन करें',
    register: 'पंजीकरण करवाना'
  },
  account: {
    profit: "आज लाभ",
    win: "आज की जीत",
    balance: "संतुलन",
    recharge: "फिर से दाम लगाना",
    withdraw: "निकालना",
    wallet: "बटुआ",
    notice: "सूचना",
    funds: "मेरा धन प्रवाहित होता है",
    pass: "पास संशोधित करें",
    password: "फंड पासवर्ड बदलें",
    exit: "सुरक्षित निकास",
    arecord: "खाता परिवर्तन रिकॉर्ड",
    rrecord: "रिचार्ज रिकार्ड",
    wrecord: "रिकॉर्ड वापस लें",
  },
  home: {
    trade: "व्यापारिक मंजिल",
    play: "गेमप्ले",
  },
  rule: {
    title: "गेमप्ले",
    p1: "0-13 छोटा है, 14-27 बड़ा है",
    p2: "सिंगल और डबल:",
    p3: "निकाली गई संख्याएँ सम संख्याएँ हैं, उदाहरण के लिए: 0, 2, 4, 6, 8, 10",
    p4: "जारी किए गए नंबर विषम संख्याएं हैं, उदाहरण के लिए: 1,3,5,7,9,11",
    p5: "बड़ा सिंगल बड़ा डबल:",
    p6: "उदाहरण के लिए, संख्या ≥ 14 बड़ी और सम है; 14 (बड़ा सम) 15 (बड़ा विषम)",
    p7: "छोटा सिंगल और छोटा डबल:",
    p8: "संख्या ≤ 13 छोटी और विषम है, उदाहरण के लिए: 13 (छोटा विषम) 12 (छोटा दोहरा)",
    p9: "छोटा सिंगल और छोटा डबल:",
    p10: "संख्या ≤ 13 छोटी और विषम है, उदाहरण के लिए: 13 (छोटा विषम) 12 (छोटा दोहरा)",
    p11: "खेल के नियम:",
    p12: "1. नकद निकासी से पहले शीर्ष स्कोर मूलधन के टर्नओवर का दोगुना है।",
    p13: "2. सिंगल और डबल जोड़े के आकार के मिलान के लिए समान संख्या या अन्य तरीकों का उपयोग करना मना है।",
    p14: "3. दुर्भावनापूर्ण धोखाधड़ी से पुरस्कार राशि जीतना सख्त वर्जित है।",
    p15: "4. अपग्रेड किए गए सदस्यों को नकदी निकालने से पहले संबंधित टर्नओवर पूरा करना होगा।",
    p16: "[नोट: अंतिम व्याख्या का अधिकार इस मंच का है~]",
  },
  indoor: {
    size: 'आकार',
    number: 'संख्या',
    special: 'विशेष',
    until: 'जब तक',
    period: 'अवधि',
    user: 'उपयोगकर्ता',
    number: 'संख्या',
    categroy: 'वर्ग',
    transaction: 'व्यापार',
    operation: 'संचालन',
    balance: 'संतुलन',
    periods: 'ISSUE',
    total: 'कुल',
    result: 'परिणाम',
    text: 'खेलने की विधि चुनें और दांव लगाने के लिए राशि पर क्लिक करें'
  },
  withdraw: {
    cash: 'राशि वापस ले',
    all: 'सब वापस ले लो'
  },
  register: {
    title: 'भरें',
    username: 'कृपया 6-20 अक्षर, संख्याएँ या संयोजन दर्ज करें',
    step: 'अगला कदम',
    register: 'सदस्य पंजीकरण'
  }
};
