import request from "../axios";

export function login(username, password, status) {
  let form = {
    username: username,
    password: password,
    status: status,
  };
  return request({
    url: "/user/doLogin",
    method: "post",
    data: form,
  });
}

export function logout() {
  return request({
    url: "/user/logout",
    method: "delete",
  });
}

export function getInfo() {
  return request({
    url: "/user/info",
    method: "get",
  });
}

export function sore(userId) {
  return request({
    url: "/user/sore/" + userId,
    method: "get",
  });
}

export function roomList(gameId) {
  return request({
    url: "/room/list/" + gameId,
    method: "get",
  });
}

export function gameData(roomId, gameId, userId) {
  return request({
    url: "/room/gameData/" + roomId + "/" + gameId + "/" + userId,
    method: "get",
  });
}

export function getPeriodIng() {
  return request({
    url: "/room/ing",
    method: "get"
  })
}

export function getGameInfo(gameId) {
  return request({
    url: "/game/" + gameId,
    method: "get",
  });
}

export function order(data) {
  return request({
    url: "/order/order",
    method: "post",
    data,
  });
}

export function getPeriodStatus() {
  return request({
    url: "/gameperiod/status",
    method: "get",
  });
}

export function updatePass(data) {
  return request({
    url: "/user/password",
    method: "put",
    data
  });
}

export function billList(userId, billType) {
  return request({
    url: "/bill/list/" + userId + "/" + billType,
    method: "get",
  });
}

export function rechargeBalance(data, status) {
  return request({
    url: "/bill/add/"+status,
    method: "post",
    data,
  });
}

export function getConfig() {
  return request({
    url: '/config/',
    method: 'get'
  })
}

export function captcha() {
  return request({
    url: '/user/captcha',
    method: 'get'
  })
}

export function submitAccount(data) {
  return request({
    url: '/user/accountInfo',
    method: 'put',
    data
  })
}
