export default {
  nav: {
    home: "フロントページ",
    sign: "ログイン",
    language: "言語を切り替える",
    notice: "お知らせ一覧",
    noticeDetail: "お知らせ詳細",
    wallet: "財布",
    rule: "ゲームのルール",
    withdraw: '現金を引き出す',
    fundsflow: '会計変更記録'
  },
  tabbar: {
    home: "フロントページ",
    recharge: "補充する",
    contact: "顧客サービス",
    account: "私の",
  },
  language: {
    cn: "簡体字中国語",
    tcn: "繁体字中国語",
    en: "英語",
    jp: "日本語",
    ko: "韓語",
    da: "ヒンディー語"
  },
  button: {
    language: "わかりました",
    recharge: "リチャージを送信する",
    betting: '賭け',
    betting1: '戻る',
    withdraw: '現金を引き出す',
    not1: 'ボタンのメモ',
    not2: 'アクセス',
    account: '提出する',
    login: 'ログイン',
    register: '登録する'
  },
  account: {
    profit: "今日の利益",
    win: "今日の勝利",
    balance: "バランス",
    recharge: "リチャージ",
    withdraw: "現金を引き出す",
    wallet: "財布",
    notice: "知らせ",
    funds: "私の資金の流れ",
    pass: "パスの変更",
    password: "ファンドのパスワードを変更する",
    exit: "安全な出口",
    arecord: "請求書変更記録",
    rrecord: "リチャージ記録",
    wrecord: "W出金記録",
  },
  home: {
    trade: "トレーディングフロア",
    play: "ゲームプレイ",
  },
  rule: {
    title: "ゲームプレイ",
    p1: "0～13は小さい、14～27は大きい",
    p2: "「シングルとダブル：",
    p3: "引き出される数字は偶数です (例: 0、2、4、6、8、10)。",
    p4: "発行される番号は奇数です (例: 1、3、5、7、9、11)。",
    p5: "ビッグシングルビッグダブル:",
    p6: "たとえば、数値 ≥ 14 は大きく、偶数です。 14 (大きな偶数) 15 (大きな奇数)",
    p7: "スモールシングルとスモールダブル：",
    p8: "数値 ≤ 13 は小さく奇数です。例: 13 (小さい奇数) 12 (小さい倍精度)",
    p9: "スモールシングルとスモールダブル：",
    p10: "数値 ≤ 13 は小さく奇数です。例: 13 (小さい奇数) 12 (小さい倍精度)",
    p11: "ゲームルール:",
    p12: "1. 最高スコアは、現金引き出しが可能になるまでの元本の売上高の 2 倍です。",
    p13: "2. シングルペアとダブルペアのサイズを同じ数字または他の方法で一致させることは禁止されています。",
    p14: "3. 悪質な不正当選は固くお断りします。",
    p15: "4. アップグレードされたメンバーは、現金を引き出す前に、対応する取引を完了する必要があります。",
    p16: "[注: 最終的な解釈権はこのプラットフォームに属します~]",
  },
  indoor: {
    size: 'サイズ',
    number: '番号',
    special: '特別',
    until: 'それまで',
    period: '期間',
    user: 'ユーザー',
    number: 'ラウンド数',
    categroy: 'カテゴリ',
    transaction: 'trade',
    operation: '操作する',
    balance: 'バランス',
    periods: 'ISSUE',
    total: '合計',
    result: '結果',
    lottery: '宝くじ',
    text: 'プレイ方法を選択し、賭け金をクリックします'
  },
  withdraw: {
    cash: '出金額',
    all: '全部撤回'
  },
  register: {
    title: '埋める',
    username: '6～20文字の文字と数字を組み合わせて入力してください',
    step: '次のステップ',
    register: '会員登録'
  }
};
