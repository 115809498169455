import Vue from "vue";
import vuex from "vuex";
import app from "./modules/app";
import getters from "./getters";

Vue.use(vuex);

const store = new vuex.Store({
  modules: {
    app
  },
  getters
})

export default store