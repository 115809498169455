export default {
  nav: {
    home: '首頁',
    sign: '登陸',
    language: '切換語言',
    notice: '公告列表',
    noticeDetail: '公告詳情',
    wallet: '錢包',
    rule: '遊戲規則',
    withdraw: '提現',
    fundsflow: '帳戶流水記錄'
  },
  tabbar: {
    home: '首頁',
    recharge: '儲值',
    contact: '客服',
    account: '我的'
  },
  language: {
    cn: '中文簡體',
    tcn: '中文繁體',
    en: '英語',
    jp: '日語',
    ko: '韓語',
    da: '印地語'
  },
  button: {
    language: '確定',
    recharge: '提交儲值',
    betting: '投注',
    betting1: '返回',
    withdraw: '提現',
    not1: '底注',
    not2: '准入',
    account: '提交',
    login: '登陸',
    register: '註冊'
  },
  account: {
    profit: '今日盈虧',
    win: "今日中獎",
    balance: '餘額',
    recharge: '儲值',
    withdraw: '提現',
    wallet: '錢包',
    notice: '公告',
    funds: '交易記錄',
    pass: '修改登陸密碼',
    password: '修改資金密碼',
    exit: '安全退出',
    arecord: '帳變記錄',
    rrecord: '儲值記錄',
    wrecord: '提現記錄',
  },
  home: {
    trade: '交易大廳',
    play: '遊戲玩法'
  },
  rule: {
    title: '遊戲玩法',
    p1: '0-13為小,14-27為大',
    p2: '單雙:',
    p3: '開出的號碼為雙數叫雙,例:0,2,4,6,8,10',
    p4: '開出的號碼為單數叫單,例:1,3,5,7,9,11',
    p5: '大單大雙:',
    p6: '出的號碼≥14為大且為雙數,例;14(大雙)15(大單)',
    p7: '小單小雙:',
    p8: '開出的號碼≤13為小且為單數,例:13(小單)12(小雙)',
    p9: '小單小雙:',
    p10: '小單小雙:',
    p11: '遊戲規則:',
    p12: '1.上分即本金的一倍流水,方可提現。',
    p13: '2.禁止同號或以其他方式進行大小單雙對刷行為。',
    p14: '3.嚴禁惡意套取彩金。',
    p15: '4.晉級會員需完成相對應流水方可提現。',
    p16: '【註:最終解釋權歸本平台所有~】',
  },
  indoor: {
    size: '大小',
    number: '數位',
    special: '特殊',
    until: '截止',
    period: '期',
    user: '使用者',
    number: '輪數',
    categroy: '交易品類',
    transaction: '單輪交易',
    operation: '操作',
    balance: '餘額',
    periods: '期數',
    total: '總和',
    result: '結果',
    text: '選擇玩法，點擊金額投注'
  },
  withdraw: {
    cash: '提現金額',
    all: '全部提現'
  },
  register: {
    title: 'Fill in',
    username: 'Please enter 6-20 letters, numbers or combinations',
    step: 'Next step',
    register: 'Member registration'
  }
}