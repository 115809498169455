import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store/index';

import Vant from 'vant';
import 'vant/lib/index.css';

import { Locale } from 'vant';

import enUS from 'vant/es/locale/lang/en-US';

Locale.use('en-US', enUS);

import VueI18n from 'vue-i18n';
import lang_zh_CN from '@/common/lang_zh_CN';
import lang_en from '@/common/lang_en';
import lang_jp from './common/lang_jp';
import lang_ko from './common/lang_ko';
import lang_da from './common/lang_da';
import lang_tcn from './common/lang_tcn';

import global from './common/config.js/global';

import '@/permission'

Vue.config.productionTip = false

Vue.prototype.global = global;

Vue.use(Vant);

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'en',
  messages: {
    'en': lang_en,
    'cn': lang_zh_CN,
    'jp': lang_jp,
    'ko': lang_ko,
    'da': lang_da,
    'tcn': lang_tcn
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
